<!--
    组件说明:
           propertys:    url(必须）下载地址
                         fileName(可选)缺省下载文件名
                         replaceFileName(可选)强制替换服务器返回的文件名
                         data(可选)请求时提供更多数据
-->
<template>
      <div>
        <img ref="image" :src="blobUrl"/>
        <el-link v-if="fileName" href="#" :underline="false" style="margin-left: 10px;font-size: 15px;color:#409EFF;">下载</el-link>
      </div>
</template>

<script>
    export default {
        props:{
            url:{
                type: String,
                required: true
            },
            fileName: {
                type: String
            },
            //替换fileName和response返回的fileName
            replaceFileName:{
              type: String,
              default: ''
            },
            data:{
              type: Object,
              default: null
            }
        },
        data(){
          return{
            blobUrl: null,
          }
        },
        methods: {
          processResponse(response){
            // console.log(response)
            //返回的是一个错误
            if(response.headers['content-type']==='application/json'){
              let reader = new FileReader();
              reader.onload = e => this.$message.error(JSON.parse(e.target.result).msg);
              reader.readAsText(response.data);
              return
            }
            //显示图片
            this.blobUrl = window.URL.createObjectURL(response.data);
            //显示下载按钮
            let fileName = this.fileName;
            if(!fileName) return
            if(response.headers["content-disposition"]){
              const patt = new RegExp("filename=([^;]+\\.[^\\.;]+);*");
              const result = patt.exec(response.headers["content-disposition"]);
              if(result.length>1){
                fileName = decodeURI(result[1]);
              }
            }
            if(this.replaceFileName){
              fileName = this.replaceFileName
            }
            const url = window.URL.createObjectURL(new Blob([response.data],
                { type: 'application/octet-stream' }));
            const link = document.querySelector('.el-link');
            link.href = url;
            link.setAttribute('download', fileName);
          }
        },
        watch: {
          url: {
            immediate: true,
            handler(src) {
              if (!src) return
              // console.log(src)
              if(this.data){
                this.$axios.post(this.url, this.data, {
                  responseType: 'blob', // important
                  timeout: 20000,
                }).then((response) => {
                  this.processResponse(response)
                });
              }else{
                this.$axios.get(this.url, {
                  responseType: 'blob', // important
                  timeout: 20000,
                }).then((response) => {
                  this.processResponse(response)
                });
              }

            },
          },
        },
    }
</script>

<style scoped>

</style>